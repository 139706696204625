import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconVacation.module.css'
import { isUserOnVacation } from '../../util/dates';

import palmTreeIcon from './../../assets/palm-tree-icon.svg';

const IconVacation = props => {
  const { user, className } = props;

  const {isValid: isValidVacation } = isUserOnVacation(user);

  return (
    isValidVacation ?
    <div className={classNames(css.vacationIcon, className)}>
      <img src={palmTreeIcon}/>
    </div>
  : null);
};

IconVacation.defaultProps = { className: null };

const { string } = PropTypes;

IconVacation.propTypes = {
  className: string,
};

export default IconVacation;
