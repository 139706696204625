import React from 'react';
import PropTypes from 'prop-types';

const IconInbox = props => {
  const { className } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/></svg>
  );
};

IconInbox.defaultProps = { className: null };

const { string } = PropTypes;

IconInbox.propTypes = {
  className: string,
};

export default IconInbox;