import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ListingWatermark.module.css'
import { isUserOnVacation } from '../../util/dates';

const ListingWatermark = props => {
  const { user, className, label = 'Vacation' } = props;

  const {isValid: isValidVacation } = isUserOnVacation(user);

  return (
    isValidVacation ?
    <div className={classNames(css.listingWatermark, className)}>
      {label}
    </div>
  : null);
};

ListingWatermark.defaultProps = { className: null };

const { string } = PropTypes;

ListingWatermark.propTypes = {
  className: string,
};

export default ListingWatermark;
