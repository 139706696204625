import _ from 'lodash';

export const processLovedListing = (params) => {
  const {
    listingId,
    dispatch,
    updateProfileRequest,
    getState,
    sdk,
    updateProfileSuccess,
    currentUserShowSuccess,
    denormalisedResponseEntities,
    updateProfileError,
    storableError,
  } = params;
  dispatch(updateProfileRequest());

  const { currentUser } = getState().user;
  const { attributes: userAttributes } = currentUser;
  const { wishlist } = userAttributes.profile.privateData;

  // If listing is already saved, remove it from the list
  const newSavedListings = _.xor(wishlist, [listingId.uuid]);

  const queryParams = {
    expand: true,
    include: ['profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  return sdk.currentUser
    .updateProfile({
      privateData: {
        wishlist: newSavedListings,
      }
    }, queryParams)
    .then(response => {
      dispatch(updateProfileSuccess(response));

      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      const currentUser = entities[0];

      // Update current user in state.user.currentUser through user.duck.js
      dispatch(currentUserShowSuccess(currentUser));
    })
    .catch(e => dispatch(updateProfileError(storableError(e))));
};

const dimensionsUnits = [
  { value: 'cm', label: 'cm' },
  { value: 'in', label: 'in' },
  { value: 'mm', label: 'mm' },
  { value: 'm', label: 'm' },
  { value: 'ft', label: 'ft' },
  { value: 'yd', label: 'yd' },
];

const weightUnits = [
  { value: 'lb', label: 'lb' },
  { value: 'kg', label: 'kg' },
  { value: 'oz', label: 'oz' },
  { value: 'g', label: 'g' },
];

const availableGuides = [
  'cat-men',
  'cat-women',
];

const mensSizeGuide = [
  { type: 'chest', label: 'Chest', unit: 'cm' },
  { type: 'waist', label: 'Waist', unit: 'cm' },
  { type: 'hip', label: 'Hip', unit: 'cm' },
  { type: 'inseam', label: 'Inseam', unit: 'cm' },
  { type: 'height', label: 'Height', unit: 'cm' }
];

const womensSizeGuide = [
  { type: 'bust', label: 'Bust', unit: 'cm' },
  { type: 'waist', label: 'Waist', unit: 'cm' },
  { type: 'hip', label: 'Hip', unit: 'cm' },
  { type: 'thigh', label: 'Thigh', unit: 'cm' },
  { type: 'inseam', label: 'Inseam', unit: 'cm' },
  { type: 'height', label: 'Height', unit: 'cm' }
];

export const getUnits = () => {
  return {
    dimensionsUnits,
    weightUnits,
    mensSizeGuide,
    womensSizeGuide,
    availableGuides
  };
};
